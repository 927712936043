import React, { useState } from 'react'
import { Mail, Phone, MapPin, Instagram, ExternalLink, ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

export default function PrivateInfo() {
  const [activeCard, setActiveCard] = useState(null)

  return (
    <section className="py-16 bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-[radial-gradient(#ede738_1px,transparent_1px)] bg-[length:20px_20px]" aria-hidden="true"></div>
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-4xl font-bold text-center mb-12 text-gray-800 tracking-wider">
          About Our Company
          <span className="block h-1 w-20 bg-[#ede738] mx-auto mt-4 rounded-full"></span>
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-8">
            <FuturisticCard
              title="Business Address"
              icon={MapPin}
              isActive={activeCard === 0}
              onClick={() => setActiveCard(activeCard === 0 ? null : 0)}
            >
              <p className="flex items-start text-gray-800">
                <span>312 W 2ND ST STE 3625, Casper, WY 82601, USA</span>
              </p>
            </FuturisticCard>
            <FuturisticCard
              title="Contact Information"
              icon={Phone}
              isActive={activeCard === 1}
              onClick={() => setActiveCard(activeCard === 1 ? null : 1)}
            >
              <ul className="space-y-3">
                <li className="flex items-center">
                  <Mail className="mr-2 h-5 w-5 text-[#ede738]" />
                  <span className="text-gray-800">Primary Email: </span>
                  <a href="mailto:Contact@weberz-agency.com" className="ml-1 text-gray-600 hover:underline">
                    Contact@weberz-agency.com
                  </a>
                </li>
                <li className="flex items-center">
                  <Mail className="mr-2 h-5 w-5 text-[#ede738]" />
                  <span className="text-gray-800">Alternative Email: </span>
                  <a href="mailto:contact.weberz@gmail.com" className="ml-1 text-gray-600 hover:underline">
                    contact.weberz@gmail.com
                  </a>
                </li>
                <li className="flex items-center">
                  <Phone className="mr-2 h-5 w-5 text-[#ede738]" />
                  <span className="text-gray-800">Primary Phone: </span>
                  <a href="tel:+13074001101" className="ml-1 text-gray-600 hover:underline">
                    +1 307-400-1101
                  </a>
                </li>
                <li className="flex items-center">
                  <Phone className="mr-2 h-5 w-5 text-[#ede738]" />
                  <span className="text-gray-800">Customer Support: </span>
                  <a href="tel:+447456443839" className="ml-1 text-gray-600 hover:underline">
                    +44 7456-443839
                  </a>
                </li>
              </ul>
            </FuturisticCard>
          </div>
          <div className="space-y-8">
            <FuturisticCard
              title="Quick Links"
              icon={ExternalLink}
              isActive={activeCard === 2}
              onClick={() => setActiveCard(activeCard === 2 ? null : 2)}
            >
              <ul className="space-y-3">
                <li className="flex items-center">
                  <ExternalLink className="mr-2 h-5 w-5 text-[#ede738]" />
                  <HashLink to="/#AboutUs" className="text-gray-600 hover:underline">
                    Why Weberz
                  </HashLink>
                </li>
                <li className="flex items-center">
                  <ExternalLink className="mr-2 h-5 w-5 text-[#ede738]" />
                  <Link to="/" className="text-gray-600 hover:underline">
                    Our Home Page
                  </Link>
                </li>
                <li className="flex items-center">
                  <Instagram className="mr-2 h-5 w-5 text-[#ede738]" />
                  <Link to="https://www.instagram.com/web_erz/" className="text-gray-600 hover:underline">
                    Our Verified Instagram Page
                  </Link>
                </li>
              </ul>
            </FuturisticCard>
            <FuturisticCard
              title="About Us"
              icon={ChevronRight}
              isActive={activeCard === 3}
              onClick={() => setActiveCard(activeCard === 3 ? null : 3)}
            >
              <p className="text-gray-800 mb-4">
                Weberz Agency is a leading digital solutions provider. We specialize in web development, digital
                marketing, and innovative online strategies to help businesses thrive in the digital landscape.
              </p>
              <HashLink to="/#AboutUs"
                className="inline-block px-6 py-3 bg-[#ede738] text-black rounded-full hover:bg-[#d6d032] transition-colors duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#ede738] focus:ring-opacity-50"
              >
                Learn More About Us
              </HashLink>
            </FuturisticCard>
          </div>
        </div>
      </div>
    </section>
  )
}

function FuturisticCard({ title, icon: Icon, children, isActive, onClick }) {
  return (
    <div
      className={`bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg border border-gray-200 overflow-hidden transition-all duration-300 ease-in-out ${
        isActive ? 'ring-2 ring-gray-300' : ''
      }`}
    >
      <button
        className="w-full text-left p-6 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
        onClick={onClick}
      >
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-semibold text-gray-800 flex items-center">
            <Icon className="mr-2 h-6 w-6 text-[#ede738]" />
            {title}
          </h3>
          <ChevronRight
            className={`h-5 w-5 text-[#ede738] transform transition-transform duration-300 ${
              isActive ? 'rotate-90' : ''
            }`}
          />
        </div>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isActive ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <div className="text-gray-800">
            {children}
          </div>
        </div>
      </button>
    </div>
  )
}