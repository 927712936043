import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function PrivacyPolicy() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className='flex flex-col sectionServices w-full xl:px-40 px-3 py-20 gap-5 '>
      <h1 className='text-[50px] text-center mb-12 font-[700]'  >Privacy Policy</h1>
      <p>This Privacy Policy describes how we, a multi-services agency specializing in e-commerce and marketing, collect, use, and protect your personal information when you visit and use our website (the "Site"). By using the Site, you agree to the collection and use of information in accordance with this policy.</p>
      <b>1. Information We Collect</b>
      <p>We collect the following types of information:</p>
      <ul>
        <li><strong>Personal Identification Information:</strong> This includes details like your name, email address, phone number, etc., that you provide when filling out forms on our Site.</li>
        <li><strong>Non-Personal Identification Information:</strong> We may collect non-personal information such as browser type, device information, and usage data to improve your experience on the Site.</li>
        <li><strong>Cookies:</strong> For information about how we use cookies, please see our <a href="/cookie-policy">Cookie Policy</a>.</li>
      </ul>
      <b>2. How We Use Your Information</b>
      <p>We use the collected information for various purposes, including:</p>
      <ul>
        <li>To provide and maintain the Site</li>
        <li>To personalize your experience and improve customer service</li>
        <li>To tailor our marketing strategies and enhance your e-commerce interactions</li>
        <li>To communicate with you, respond to inquiries, and provide support</li>
        <li>To comply with legal obligations and prevent fraud</li>
      </ul>
      <b>3. Sharing Your Information</b>
      <p>We do not sell, trade, or otherwise transfer your personal identification information to third parties, except in the following cases:</p>
      <ul>
        <li>To trusted partners who assist us in operating the Site, conducting our business, or serving our users, provided they agree to keep this information confidential.</li>
        <li>To comply with legal requirements or to protect our rights, property, or safety.</li>
      </ul>
      <b>4. Data Retention</b>
      <p>We will retain your personal information only as long as necessary to fulfill the purposes for which it was collected, including legal, accounting, or reporting requirements.</p>
      <b>5. Your Rights</b>
      <p>You have the right to request access to, correction of, or deletion of your personal data. To exercise these rights, please contact us at the address provided below.</p>
      <b>6. Security of Your Information</b>
      <p>We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>
      <b>7. Third-Party Links</b>
      <p>Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to read the privacy policies of any third-party sites you visit.</p>
      <b>8. Changes to This Privacy Policy</b>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will be effective as of the date it is posted.</p>
      <b>9. Contact Us</b>
      <p>If you have any questions about this Privacy Policy or your personal data, please contact us at:</p>
      <p>Email: <a href="mailto:accounts@weberz-agency.com">accounts@weberz-agency.com</a><br />
        Address: 312 W 2ND ST STE 3625, CASPER, WY 82601, US</p>
    </div>



  )
}

export default PrivacyPolicy