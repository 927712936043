import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Form from '../components/Form'
import Faq from '../components/Faq'
import { Link } from 'react-router-dom';

function PaymentGateaway() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className='grid  place-items-center w-full sectionServices'>
      <section className="w-full sectionServices pb-10 h-[65vh] relative bg-gray-50">
        <div className="relative z-10 w-full h-full">

          <div className="flex   w-full h-full justify-center xl:flex-row flex-col xl:-mt-[20px] mt-[40px] px-2 items-center xl:px-24  px-3">

            <div className="flex flex-col w-full xl:w-1/2 text-center  h-full justify-center xl:gap-5 gap-2 pb-14 xl:pb-0   items-center">
              <h4 className="font-[800] capitalize leading-[44px] xl:leading-[64px] text-[34px] xl:text-[58px] text-neutral-800 animate-pulse"> Launching Shortly...!
              </h4>
              <p className="font-[400] leading-[36px]  text-[17px] xl:text-[18px] text-neutral-800"> Get Ready to Simplify Your Transactions with Our Full Pack Payment Gateway Solutions!
              </p>
              <Link to='/' className="text-neutral-800 hover:scale-105 rounded-full border-b-[4px] hover:border-b-[1px] mt-3 scroll-link uppercase font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 xl:px-10 px-3 transition-all duration-300">
                Return to home
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </Link>
            </div>

          </div>
        </div>
      </section>
      {/* 
      <Form />
      <Faq /> */}
    </div>
  )
}

export default PaymentGateaway