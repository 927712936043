import React from 'react'
import animationData from '../animations/AnimationQuestion.json'
import Lottie from 'react-lottie';
function Benifits() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (

        <section id="Benifits" className="w-full px-2 h-full py-10 xl:py-20 relative bg-black">
            {/* Overlay */}
            {/* <div class="absolute inset-0 bg-black z-10 opacity-60"></div> */}
            {/* Background Image */}
            {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/stars.avif');"></div> */}
            {/* Content */}
            <div className="relative z-10 flex justify-center items-center w-full h-full">
                <div className="flex xl:flex-row flex-col justify-center xl:gap-12   items-center w-full  px-2 sm:px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-40">
                    <div className="xl:flex hidden xl:justify-start justify-center items-center w-1/2">
                        <Lottie
                            options={defaultOptions}
                            height={600}
                            width={600}
                        />
                    </div>
                    <div className="flex xl:hidden xl:justify-start justify-center items-center w-full">
                        <Lottie
                            options={defaultOptions}
                            height={300}
                            width={300}
                        />
                    </div>
                    <div className="flex flex-col mt-10 xl:mt-0  justify-center items-center xl:items-start gap-8 text-neutral-100 w-full xl:w-1/2">
                        <i class="fa-solid fa-quote-left text-[80px]"></i>
                        <h4 className="font-[700] xl:text-start pb-10 text-center uppercase leading-[36px] xl:text-[40px] text-[20px] text-neutral-100">
                            Have you ever wondered :</h4>
                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[15px] text-neutral-100">


                            Why can Big E-commerce players keep advertising smoothly and making orders every day while you struggle with account bans and payment gateway issues?
                        </p>
                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[15px] text-neutral-100">

                            <b className='text-[ #ffde59]'>The Answer is</b>  : They use <b>advanced anti-ban structures</b>  With strong <b>premium agency ad accounts</b>  created and validated by partners like us.


                        </p>
                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[15px] text-neutral-100">
                            <b className='text-[ #ffde59]'>With Weberz</b>  , get an  Advanced Anti-Ban Structure that keeps your ads running even if you are permanently banned. Our reliable methods are <b>100% bulletproof</b> , along with Full Payment Gateway Packages.
                        </p>



                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[15px] text-neutral-100">

                            We offer<b className='animate-pulse text-[ #ffde59] text-[19px]'> an all-in-one</b> , ready-to-use service to ensure your advertising efforts are as successful as possible.

                        </p>
                        <div className="flex flex-col gap-3 justify-center items-start">
                            <a href="#form" className="text-neutral-100 rounded-full border-b-[4px] hover:border-b-[1px] hover:scale-105 scroll-link uppercase font-[700] leading-[22px] hover:bg-white hover:text-gray-900 text-[15px]  xl:text-[15px] flex gap-2 items-center justify-center w-fit border border-neutral-300 py-3 xl:px-10 px-5 transition-all duration-300">
                                Become a part of our vip clients

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </a>
                            <div className="flex xl:justify-start justify-center w-full items-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-white">
                                    <defs>
                                        <linearGradient id="half-yellow" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="50%" style={{ stopColor: "#ffde59", stopOpacity: 1 }} />
                                            <stop offset="50%" style={{ stopColor: "currentColor", stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#half-yellow)" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>

                                <p className="font-[500] capitalize leading-[15px] text-[15px] text-neutral-100 ml-2"> Based
                                    on 3500+
                                    Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div></section >

    )
}

export default Benifits