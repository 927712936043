import React , {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import AboutUs from '../components/AboutUs'
import Hero from '../components/Hero'
import Faq from '../components/Faq'
import Services from '../components/Services'
import Benifits from '../components/Benifits'
import Form from '../components/Form'
import Companies from '../components/Companies'
function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className='w-full h-full grid'>
        <Hero />
        <Companies />
        <AboutUs />
        <Benifits />
        <Services />
        <Form />
        <Faq />

    </div>
  )
}

export default Home