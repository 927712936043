import React, { useState, useEffect, useRef } from 'react';

const NumberTicker = ({ start = 0, end, duration = 2000, suffix = '' }) => {
    const [current, setCurrent] = useState(start);
    const [inView, setInView] = useState(false);
    const tickerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            {
                threshold: 0.1, // Adjust this to control when the element is considered in view
            }
        );

        if (tickerRef.current) {
            observer.observe(tickerRef.current);
        }

        return () => {
            if (tickerRef.current) {
                observer.unobserve(tickerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!inView) return;

        const startTime = Date.now();
        const endTime = startTime + duration;

        const updateNumber = () => {
            const now = Date.now();
            if (now >= endTime) {
                setCurrent(end);
                return;
            }
            const progress = (now - startTime) / duration;
            setCurrent(Math.floor(start + progress * (end - start)));
            requestAnimationFrame(updateNumber);
        };

        updateNumber();
    }, [inView, start, end, duration]);

    return (
        <span
            ref={tickerRef}
            className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800"
        >
            {current}{suffix}
        </span>
    );
};

export default NumberTicker;
